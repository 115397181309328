import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, CanMatchFn, Params, Router, UrlSegment, UrlTree } from '@angular/router';
import { combineLatest, map, Observable, of, switchMap } from 'rxjs';

import { LaunchDarklyService, LDFeatureKey } from '@@core/services/launch-darkly.service';
import { IntegrationAuthData } from '@@shared/stores/auth-store/models/auth-store.models';
import { AuthStore } from '@@shared/stores/auth-store/stores/auth.store';

import { FeatureService } from '../services/features.service';

export const ldFeatureMatchGuard: CanMatchFn = (route: ActivatedRouteSnapshot, segments: UrlSegment[]) => {
	const featureService = inject(FeatureService);
	const ldService = inject(LaunchDarklyService);
	const authStore = inject(AuthStore);
	const router = inject(Router);

	const features = route.data?.features as LDFeatureKey[] || [];
	const disabledFeatures = route.data?.disabledFeatures as LDFeatureKey[] || [];
	const ignoreUserRoleFeature = !!route.data?.ignoreUserRoleFeature;

	return checkRouteEnabled(features, disabledFeatures, ignoreUserRoleFeature, featureService, ldService, authStore.integrations()).pipe(
		map(routeEnabled => {
			if (!routeEnabled) {
				const params = route.params || {};
				const queryParams = route.queryParams || {};
				const redirectTo = route.data?.redirectTo as string || '/';

				if (route.data?.keepQueryParams) {
					return replaceUrlSegments(router, redirectTo, params, queryParams);
				}
				return router.createUrlTree([redirectTo]);
			}
			return true;
		})
	);
};

const checkRouteEnabled = (features: LDFeatureKey[], disabledFeatures: LDFeatureKey[], ignoreUserRole: boolean, featureService: FeatureService, ldService: LaunchDarklyService, integrations: IntegrationAuthData): Observable<boolean> => ldService.ldInitialized$.pipe(
	switchMap((isInitialized: boolean) => {
		if (!isInitialized) {
			const { context, hash } = integrations.ld;
			return ldService.initialize(context, hash);
		} else {
			return of(true);
		}
	}),
	switchMap(() => {
		const isFeatureActive: Observable<boolean> = isAtLeastOneFlagActive(features, ignoreUserRole, featureService);
		const isDisabledFeatureActive: Observable<boolean> = isAtLeastOneDisabledFlagActive(disabledFeatures, featureService);

		return combineLatest([isFeatureActive, isDisabledFeatureActive]).pipe(
			map(([featureActive, disabledFeatureActive]) => featureActive || (disabledFeatures?.length > 0 && !disabledFeatureActive))
		);
	})
);

const isAtLeastOneFlagActive = (features: LDFeatureKey[], ignoreUserRole: boolean, featureService: FeatureService): Observable<boolean> => {
	if (!features || features.length === 0) {
		return of(false);
	}

	const observables = features.map(feature => {
		if (ignoreUserRole) {
			return featureService.isFeatureOn(feature);
		} else {
			return featureService.isAdminOrFeatureActive(feature);
		}
	});

	return combineLatest(observables).pipe(
		map(results => results.some(result => result))
	);
};

const isAtLeastOneDisabledFlagActive = (features: LDFeatureKey[], featureService: FeatureService): Observable<boolean> => {
	if (!features || features.length === 0) {
		return of(false);
	}

	const observables = features.map(feature => featureService.isNonAdminAndFeatureActive(feature));

	return combineLatest(observables).pipe(
		map(results => results.some(result => result))
	);
};

const replaceUrlSegments = (router: Router, redirectTo: string, params: Params, queryParams: Params): UrlTree => {
	const segments = redirectTo.split('/').map(segment => {
		if (segment.startsWith(':')) {
			const paramKey = segment.substring(1);
			return params[paramKey] as string[];
		}
		return segment;
	});
	return router.createUrlTree(segments, { queryParams });
};
